<template>
  <div class="mark-box">
    <correctTheoryPaper :logId="logId" :headData="headData" :answerData="answerData" />
  </div>
</template>

<script>

import correctTheoryPaper from '@/components/correctTheoryPaper.vue'
import {markStartMarkJudge, markStartMarkTeacher} from "@/utils/apis";
export default {
  name: "MarkTheoryPaper",
  components:{
    correctTheoryPaper
  },
  data(){
    return {
      logId:this.$route.query.logId || '', //	学生的答题记录ID
      headData:{},
      answerData:{},
      role:Number(localStorage.getItem('role'))|| '',
    }
  },
  mounted() {
    // 分辨评委端 教师端
    if(this.role === 4){
      this.getMarkStartMarkJudge()
    } else {
      this.getMarkStartMarkTeacher();
    }
  },
  methods:{
    // 获取评委页面批改数据
    getMarkStartMarkJudge(){
      let params = {
        student_theory_exam_log_id:this.logId
      }
      markStartMarkJudge(params).then((res)=>{
        this.headData = res.data.head_data;
        this.answerData = res.data.answer_data.list;
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    // 获取教师端页面批改数据
    getMarkStartMarkTeacher(){
      let params = {
        student_theory_exam_log_id:this.logId
      }
      markStartMarkTeacher(params).then((res)=>{
        this.headData = res.data.head_data;
        this.answerData = res.data.answer_data.list;
      }).catch((err)=>{
        console.log('err',err)
      })
    }
  }
}
</script>

<style scoped lang="scss">

.mark-box{
  padding: 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>